
.typeTooltip{
    position: relative;
    width:40px;
    height:40px;
    .tooltipBox{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        .tooltip{
            visibility:hidden;
            opacity: 0;
            position: absolute;
            top:-35px;
            left:50%;
            transform: translate(-50%, 0);
            padding:0 12px;
            border-radius: 4px;
            box-shadow: 0 2px 2px 0 var(--dark-opacity-60);
            background-color: var(--dark-grey-80);
            z-index: 10;
            text-align:center;
            white-space: pre;
            transition: all 0.3s;
            height:28px;
            line-height:28px;
            &:after{
                position:absolute;
                top:26px;
                left:50%;
                transform: translate(-50%, 0);
                z-index: 10;
                display:inline-block;
                content: '';
                border-top:6px solid var(--dark-grey-80);
                border-bottom:6px solid transparent;
                border-right: 6px solid transparent;
                border-left: 6px solid transparent;
            }
        }
        .typeIcon{
            width:38px;
            height:38px;
            border-radius: 8px;
            border:1px solid var(--dark-grey-40);
            display:flex;
            align-items: center;
            justify-content: center;
            background-color:rgba(0,0,0,0);
            svg{
                fill:var(--dark-grey-40);
                width:28px;
                height:28px;
            }
            img{
                width:100%;
            }
        }
        // &:hover{
        //     span{
        //         visibility: visible;
        //         opacity: 1;
        //         top:-40px;
        //     }
        // }
        &.on{
            .typeIcon{
                border:1px solid var(--dark-main-blue-50);
                svg{
                    fill:var(--dark-main-blue-50);
                }
            }
        }
    }
 
}