.cscenter--btn {
  align-self: center;
  width: 240px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: solid 1px var(--white);
  color: var(--white);
  cursor: pointer;
  background-color: var(--black-2);
}

.date-filter {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: var(--white);
  span {
    user-select: none;
  }
}

.return_btn {
  width: 108px;
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: solid 1px var(--white);
  text-align: left;
  color: var(--white);
  cursor: pointer;
  margin: 22px 0px;
}
