
@font-face {
    font-family: 'pass';
    font-style: normal;
    font-weight: 400;
    src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAATsAA8AAAAAB2QAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAcg9+z70dERUYAAAF0AAAAHAAAAB4AJwANT1MvMgAAAZAAAAA/AAAAYH7AkBhjbWFwAAAB0AAAAFkAAAFqZowMx2N2dCAAAAIsAAAABAAAAAQAIgKIZ2FzcAAAAjAAAAAIAAAACAAAABBnbHlmAAACOAAAALkAAAE0MwNYJ2hlYWQAAAL0AAAAMAAAADYPA2KgaGhlYQAAAyQAAAAeAAAAJAU+ATJobXR4AAADRAAAABwAAAAcCPoA6mxvY2EAAANgAAAAEAAAABAA5gFMbWF4cAAAA3AAAAAaAAAAIAAKAE9uYW1lAAADjAAAARYAAAIgB4hZ03Bvc3QAAASkAAAAPgAAAE5Ojr8ld2ViZgAABOQAAAAGAAAABuK7WtIAAAABAAAAANXulPUAAAAA1viLwQAAAADW+JM4eNpjYGRgYOABYjEgZmJgBEI2IGYB8xgAA+AANXjaY2BifMg4gYGVgYVBAwOeYEAFjMgcp8yiFAYHBl7VP8wx/94wpDDHMIoo2DP8B8kx2TLHACkFBkYA8/IL3QB42mNgYGBmgGAZBkYGEEgB8hjBfBYGDyDNx8DBwMTABmTxMigoKKmeV/3z/z9YJTKf8f/X/4/vP7pldosLag4SYATqhgkyMgEJJnQFECcMOGChndEAfOwRuAAAAAAiAogAAQAB//8AD3jaY2BiUGJgYDRiWsXAzMDOoLeRkUHfZhM7C8Nbo41srHdsNjEzAZkMG5lBwqwg4U3sbIx/bDYxgsSNBRUF1Y0FlZUYBd6dOcO06m+YElMa0DiGJIZUxjuM9xjkGRhU2djZlJXU1UDQ1MTcDASNjcTFQFBUBGjYEkkVMJCU4gcCKRTeHCk+fn4+KSllsJiUJEhMUgrMUQbZk8bgz/iA8SRR9qzAY087FjEYD2QPDDAzMFgyAwC39TCRAAAAeNpjYGRgYADid/fqneL5bb4yyLMwgMC1H90HIfRkCxDN+IBpFZDiYGAC8QBbSwuceNpjYGRgYI7594aBgcmOAQgYHzAwMqACdgBbWQN0AAABdgAiAAAAAAAAAAABFAAAAj4AYgI+AGYB9AAAAAAAKgAqACoAKgBeAJIAmnjaY2BkYGBgZ1BgYGIAAUYGBNADEQAFQQBaAAB42o2PwUrDQBCGvzVV9GAQDx485exBY1CU3PQgVgIFI9prlVqDwcZNC/oSPoKP4HNUfQLfxYN/NytCe5GwO9/88+/MBAh5I8C0VoAtnYYNa8oaXpAn9RxIP/XcIqLreZENnjwvyfPieVVdXj2H7DHxPJH/2/M7sVn3/MGyOfb8SWjOGv4K2DRdctpkmtqhos+D6ISh4kiUUXDj1Fr3Bc/Oc0vPqec6A8aUyu1cdTaPZvyXyqz6Fm5axC7bxHOv/r/dnbSRXCk7+mpVrOqVtFqdp3NKxaHUgeod9cm40rtrzfrt2OyQa8fppCO9tk7d1x0rpiQcuDuRkjjtkHt16ctbuf/radZY52/PnEcphXpZOcofiEZNcQAAeNpjYGIAg///GBgZsAF2BgZGJkZmBmaGdkYWRla29JzKggxD9tK8TAMDAxc2D0MLU2NjENfI1M0ZACUXCrsAAAABWtLiugAA) format('woff');
}

.rectangelTextField{
    // width:320px;
    width:100%;
    flex:1;
    .inputArea{
        padding:0 16px;
        border-radius: 4px;
        border:1px solid rgba(255,255,255,0.3);
        display:flex;
        gap:16px;
        justify-content: space-between;
        align-items: center;
        height:50px;
        .input{
            outline: none;
            width:100%;
            height:100%;
            color:var( --white);
            &[type="password"]{
                font-family: 'pass', 'Roboto', Helvetica, Arial, sans-serif ;
                font-size:20px;
            }
        }
     
        &.focus{
            border-color:var(--dark-main-blue-50);
        }
        .button{
            display:inline-block;
        }
    }
    .error{
        color:var(--dark-sub-coral);
        display:flex;
        // align-items: center;
        margin-top:4px;
        position: relative;
        span{
            position:absolute;
            top:0;
            left:0;
            min-width:232px;
            max-width:300px;
            margin-left:20px;
        }
    }
    .confirm{
        color:var(--dark-sub-green);
        display:flex;
        align-items: center;
        margin-top:4px;
        position: relative;
        span{
            position:absolute;
            top:0;
            left:0;
            min-width:200px;
            max-width:300px;
            margin-left:20px;

        }
    }
    .msg{
        color:var(--dark-grey-50);
        display:flex;
        align-items: center;
        margin-top:4px;
        // position: relative;
        span{
            // position:absolute;
            top:0;
            left:0;
            min-width:200px;
            color:var(--dark-grey-50);
        }
    }
}

.rectangelTextField.error{
    .inputArea{
        border-color:var(--dark-sub-coral);
    }
}

.rectangelTextField.confirm{
    .inputArea{
        border-color:var(--dark-sub-green);
    }
}


//검색 모달 scss
.searchModal{
    .inputArea{
        border-radius: 8px;
        border:1px solid #262626;
        height:40px;
        line-height:40px;
        background-color:var(--dark-opacity-20);
        gap:12px;
        .input{
            font-size:14px;
            line-height:40px;
        }
        &.focus{
            border-color:var(--dark-opacity-70);
        }
        .button{
            display:inline-block;
        }
        i{
            min-width:20px;
            width:20px;
            height:20px;
            cursor: pointer;
            svg{
                width:100%;
                height:100%;
            }
        }
    }
}

//반응형
@media only screen and (max-width: 670px) {
    .rectangelTextField .inputArea .input {
        width: auto;
        flex: 1;
        display: block;
    }
}