.answerbox {
  width: 100%;
  background-color: var(--dark-opacity-10);
  color: var(--dark-grey-80);
}

.answergraph {
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2px;
    border-bottom: none;
    height: fit-content;
    .column1,
    .column2 {
      width: 300px;
      height: 36px;
      flex-grow: 0;
      background-color: var(--dark-grey-10);
      line-height: 36px;
    }
    .tcol {
      background-color: var(--dark-grey-20);
    }
    .column1 {
      color: var(--dark-grey-80);
    }
    .column2 {
      color: var(--white);
    }
    .red {
      color: var(--dark-sub-coral);
    }
  }
}

.qna-wrapper {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px var(--dark-opacity-60);
  clip: auto;
  .questionline {
    background-color: var(--black-2);
    display: flex;
    height: 100%;
    flex: 1;
    padding: 24px;
    flex-direction: row;
    justify-content: space-between;
    // z-index: 100;
    // border-bottom: solid 1px var(--dark-opacity-60);
    user-select: none;
    cursor: pointer;
    .data {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
      .qtag {
        text-align: left;
        color: var(--dark-sub-orange);
      }
      .question {
        text-align: left;
        color: var(--white);
      }
    }
    .icon {
      cursor: pointer;
    }
  }
}

.pagination-tab {
  margin-left: auto;
  margin-right: auto;
  margin-top: 76px;
  ul {
    all: unset;
  }

  li {
    float: left;
    list-style-type: none;
    cursor: pointer;
    color: #323232;
    border: 1px solid #323232;
    width: 30px;
    line-height: 30px;
    height: 30px;
    text-align: center;
    margin: 5px;
  }

  .active {
    color: #569CF2;
    outline: none;
    border: 1px solid #569CF2;
  }

  .pointers {
    font-size: 24px;
    line-height: 100%;
  }
}