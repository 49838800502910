:root {
  --primary-purple : #b10fda;
  --primary-blue : #34c5ff;
  --black: #000;
  --black-2 : #0c0c0c;
  --white: #fff;
  --black-dark-mode: #141414;
  --dark-main-navy-60: #363c90;
  --dark-main-navy-50: #121a8a;
  --dark-main-navy-40: #0e156e;
  --dark-main-navy-20: #070a37;
  --dark-main-navy-80: #787ba6;
  --dark-main-navy-70: #454a88;
  --dark-main-blue-10: #304762;
  --dark-main-blue-20: #3e5f8f;
  --dark-main-navy-30: #0b1053;
  --dark-main-blue-30: #3e69ae;
  --dark-main-blue-40: #3f79cc;
  --dark-main-blue-50: #569cf2;
  --dark-main-navy-10: #04051c;
  --dark-main-blue-60: #65aaf5;
  --dark-main-blue-80: #9dcefa;
  --dark-main-pink-10: #33011b;
  --dark-main-blue-70: #7bbaf7;
  --dark-main-pink-30: #990350;
  --dark-main-pink-20: #660235;
  --dark-main-pink-40: #cc046b;
  --dark-main-pink-60: #ff3fa1;
  --dark-main-pink-80: #ff9ace;
  --dark-main-pink-50: #ff0585;
  --dark-main-pink-70: #ff73bb;
  --dark-sub-cobalt-blue: #2558cf;
  --dark-sub-lavender-blue: #8dbaf8;
  --dark-sub-coral: #ed3137;
  --dark-sub-picton-blue: #34c5ff;
  --dark-sub-green: #07b07e;
  --dark-sub-bisque: #ffd29c;
  --dark-grey-10: #272727;
  --dark-grey-20: #323232;
  --dark-grey-30: #505050;
  --dark-grey-50: #8a8a8a;
  --dark-grey-40: #636363;
  --dark-grey-60: #ababab;
  --dark-grey-70: #d0d0d0;
  --dark-grey-80: #e2e2e2;
  --dark-opacity-20: rgba(255, 255, 255, 0.08);
  --dark-opacity-10: rgba(255, 255, 255, 0.06);
  --dark-opacity-30: rgba(255, 255, 255, 0.1);
  --dark-opacity-40: rgba(255, 255, 255, 0.12);
  --dark-opacity-50: rgba(255, 255, 255, 0.14);
  --dark-opacity-60: rgba(255, 255, 255, 0.16);
  --light-main-navy-10: #e5e6f1;
  --dark-opacity-70: rgba(255, 255, 255, 0.18);
  --dark-opacity-80: rgba(255, 255, 255, 0.2);
  --light-main-navy-20: #9397c6;
  --light-main-navy-30: #4c52a0;
  --light-main-navy-40: #272e87;
  --light-main-navy-70: #00043c;
  --light-main-navy-60: #00054b;
  --light-main-navy-50: #040a5c;
  --light-main-navy-80: #00032c;
  --light-main-blue-10: #e6f3ff;
  --light-main-blue-20: #c3e0ff;
  --light-main-blue-40: #7bb9ff;
  --light-main-blue-50: #65aaff;
  --light-main-blue-30: #9ecdff;
  --light-main-blue-60: #589cfd;
  --light-main-blue-70: #548dee;
  --light-main-blue-80: #4f7bda;
  --light-main-pink-20: #ffaed8;
  --light-main-pink-30: #ff87c5;
  --light-main-pink-10: #ffc7e4;
  --light-main-pink-40: #ff59ae;
  --light-main-pink-50: #ff319a;
  --light-main-pink-60: #dd0775;
  --light-main-pink-70: #c70066;
  --light-sub-cobalt-blue: #264a9f;
  --light-main-pink-80: #a90056;
  --light-sub-lavender-blue: #b9d6ff;
  --light-sub-picton-blue: #4ab4de;
  --light-sub-green: #00c88c;
  --light-sub-coral: #ff3737;
  --light-sub-bisque: #ffe4c4;
  --light-grey-10: #f2f2f2;
  --light-grey-20: #eaeaea;
  --light-grey-30: #d4d4d4;
  --light-grey-40: #aaa;
  --light-grey-50: #7d7d7d;
  --light-grey-60: #626262;
  --light-grey-70: #505050;
  --light-grey-80: #303030;
  --light-opacity-20: rgba(0, 0, 0, 0.08);
  --light-opacity-10: rgba(0, 0, 0, 0.06);
  --light-opacity-40: rgba(0, 0, 0, 0.12);
  --light-opacity-30: rgba(0, 0, 0, 0.1);
  --light-opacity-50: rgba(0, 0, 0, 0.14);
  --light-opacity-60: rgba(0, 0, 0, 0.16);
  --light-opacity-70: rgba(0, 0, 0, 0.18);
  --light-opacity-80: rgba(0, 0, 0, 0.2);
  --dark-sub-orange: #ffa723;
  --light-sub-orange: #ff8d23;
}

.blue{
  color : #569CF2
}

.green{
  color : #2F9D27
}

.red{
  color : #FF0000 !important
}

.ivory{
  color : #FFF2CC
}

.orange{
  color : #ffa723
}
