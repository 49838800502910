.roundBtn {
  padding: 0 12px;
  border-radius: 16px;
  -moz-border-radius: 16px !important;
  -khtml-border-radius: 16px !important;
  -webkit-border-radius: 16px !important;
  background-color: var(--dark-opacity-20);
  cursor: pointer;
  color: var(--dark-grey-60);
  outline: none;
  -webkit-tap-highlinght-color: rgba(0, 0, 0, 0);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
  i {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &:hover {
    background-color: var(--dark-opacity-40);
  }
  &:focus{
    box-shadow: 0px 0px 2px 4px #3e5f8f;
  }
  &:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
  }
  &.line {
    border: solid 1px var(--dark-grey-30);
    color: var(--dark-grey-60);
    background-color: rgba(0,0,0,0);
    svg {
      fill: var(--dark-grey-60);
    }
    &:hover {
        border: solid 1px var(--dark-opacity-80);
        background-color: var(--dark-grey-10);
    }
    &.select{
      border-color : var(--dark-main-blue-50);
      color : var(--dark-main-blue-50);
    }
  }
}
