.firstStudio {
  background: var(--black) url("../../../assets/img/img_bg_symbol_graphic.png")
    no-repeat bottom;
  background-size: 100%;
  background-attachment: fixed;
  background-position: right;
  position: relative;
  width: 100%;
  height: 100vh;
  .firstStudioContainer {
    width: 1130px;
    height: 800px;
    background: url("../../../assets/img/img_human_wire.png") no-repeat center
      right;
    background-size: 638px 800px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .logo {
    width: 172px;
    height: 100px;
    padding-top: 180px;
    svg {
      fill: var(--white);
    }
  }
  .title {
    color: var(--white);
    margin: 40px 0;
  }
  .desc {
    color: var(--white);
    white-space: pre-line;
  }
  .inputArea {
    margin: 40px 0;
    display: flex;
  }
  .btn {
    margin-top: 40px;
    width: 240px;
    button {
      display: flex;
      align-items: center;
      font-size: 16px;
      border: solid 1px var(--white);
      color: var(--white);
      svg {
        fill: var(--white);
        width: 16px;
        height: 16px;
        margin-left: 8px;
      }
    }
  }
}
