$time: 0.3s;

.exStartBtn{
  width: 218px;
  height: 56px;
  cursor: pointer;
  position: relative;
  &::before {
    width: calc(100% - 4px);
    height: 54px;
    display: block;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    border: 1px solid transparent;
    background: linear-gradient(
        104deg,
        var(--dark-sub-picton-blue) 0%,
        var(--primary-purple) 100%
      )
      border-box;
    border-radius: 30px;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
  }
  .backGradient {
    transition: all $time;
    border-radius: 30px;
    width: calc(100% - 2px);
    height: 56px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-image: linear-gradient(
      104deg,
      var(--dark-sub-picton-blue) 0%,
      var(--primary-purple) 100%
    );
    opacity: 1;
  }
  .textArrow {
    transition: all $time;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 30px;
    width:calc(100% - 2px);
    height: 54px;
    display: flex;
    align-items: center;
    .text {
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(
        102deg,
        var(--white) 0%,
        var(--white) 100%
      );
      font-size: 18px;
      font-weight: bold;
      transition: all $time;
      margin-left: 38px;
      min-width: 107px;
      max-width:150px;
    }
    .icon {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-left: 8px;
      transition: all $time;
      svg {
        width: 100%;
        height: 100%;
        fill: var(--white);
        transition: all 0.5s;
      }
    }
  }

  &:hover {
    .backGradient {
      opacity: 0;
    }
    .textArrow {
      position: absolute;
      top: 1px;
      left: 1px;
      border-radius: 30px;
      width: calc(100% - 2px);
      height: 54px;
      .text {
        margin-left: 32px;
        background-image: linear-gradient(
          102deg,
          var(--dark-sub-picton-blue) 0%,
          var(--primary-purple) 100%
        );
      }
      .icon {
        margin-left: 30px;
        svg {
          fill: var(--primary-purple);
        }
      }
    }
  }
  &.no {
    opacity: 0.2;
    cursor: default;
    &:hover {
      .backGradient {
        opacity: 1;
      }
      .textArrow {
        position: absolute;
        top: 1px;
        left: 1px;
        border-radius: 30px;
        width:calc(100% - 2px);
        height: 54px;
        .text {
          margin-left: 38px;
          background-image: linear-gradient(
            102deg,
            var(--white) 0%,
            var(--white) 100%
          );
        }
        .icon {
          margin-left: 8px;
          svg {
            fill: var(--white);
          }
        }
      }
    }
  }
}


.en .exStartBtn{
  width: 251px;
}