.creditline {
  height: 100px;
  margin-top: 32px;
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dark-opacity-10);
  .credittag {
    color: var(--white);
    user-select: none;
  }
  .creditamount {
    display: flex;
    flex-direction: row;
    gap: 24px;
    .amount {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      text-align: right;
      color: var(--dark-sub-orange);
    }
    .chargebtn {
      width: 158px;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: solid 1px var(--dark-sub-orange);
      color: var(--dark-sub-orange);
      cursor: pointer;
      user-select: none;
    }
  }
  .loadingContainer{
    height:588px;
  }
}
