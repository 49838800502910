@use "backup-font";
@use "faq";
@use "table";
@use "current-credit";
@use "button";
@use "inquiry";
@use "base";

@import "../scss/color.scss";
@import "../scss/size.scss";

@font-face {
  font-family: "Noto Sans KR";
  font-weight: 700;
  src: url("../fonts/NotoSansKR-Bold.ttf") format("woff2");
}

@font-face {
  font-family: "Noto Sans KR";
  font-weight: 500;
  src: url("../fonts/NotoSansKR-Medium.ttf") format("woff2");
}

@font-face {
  font-family: "Noto Sans KR";
  font-weight: 400;
  src: url("../fonts/NotoSansKR-Regular.ttf") format("woff2");
}

@font-face {
  font-family: "Noto Sans KR";
  font-weight: 300;
  src: url("../fonts/NotoSansKR-Light.ttf") format("woff2");
}

@font-face {
  font-family: "Noto Sans JP";
  font-weight: 700;
  src: url("../fonts/NotoSansJP-Bold.ttf") format("woff2");
}
@font-face {
  font-family: "Noto Sans JP";
  font-weight: 500;
  src: url("../fonts/NotoSansJP-Medium.ttf") format("woff2");
}

@font-face {
  font-family: "Noto Sans JP";
  font-weight: 400;
  src: url("../fonts/NotoSansJP-Regular.ttf") format("woff2");
}

@font-face {
  font-family: "Noto Sans JP";
  font-weight: 300;
  src: url("../fonts/NotoSansJP-Light.ttf") format("woff2");
}

@font-face {
  font-family: "GmarketSansBold";
  font-weight: normal;
  src: url("../fonts/GmarketSansBold.otf") format("woff2");
}

@font-face {
  font-family: "GmarketSansMedium";
  font-weight: normal;
  src: url("../fonts/GmarketSansMedium.otf") format("woff2");
}

*,
body {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR", sans-serif;
  user-select: text;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -moz-touch-callout: none;
  -moz-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-apperance: 0;
  -moz-apperance: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  background-color: var(--black);
  min-height: 100vh;
}

body {
  width: 100%;
  margin: 0 auto;
  // overflow-y: scroll !important;
  background-color: var(--black);
}

li {
  list-style: none;
}

a {
  color: var(--black);
  text-decoration: none;
}

img {
  border: 0;
  outline: none;
}

pre {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.hidden {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.App {
  background-color: #111; //var(--black);
  // min-height: calc(100vh - 192px);
  min-height: 100vh;
}

.main-section {
  background-color: #111; //var(--black);
  max-width: 1920px;
  margin: 0 auto;
}

.useinfo-container {
  display: flex;
  position: relative;
  padding: 0 75px;
  min-height: 1100px;
}

.side-nav {
  width: 220px;
  min-width: 220px;
}

.main {
  padding-left: 245px;
  padding-right: 0;
  // background-color: var(--black);
  // margin-right:75px;
}

.grayBack {
  background-color: var(--black-2);
  min-height: 100vh;
}

.innerSide {
  padding-left: 0;
}

.userinfo-secrion {
  padding-left: 75px;
  padding-right: 75px;
}

.wrapContainer {
  position: relative;
  background-color: #111; //var(--black);
  max-width: 1920px;
  margin: 0 auto;
}

.container {
  width: 1280px;
  margin: 0 auto;
}

.main-container {
  // overflow-x: hidden;
}

.blurEffect {
  // backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 1);
}

input,
textarea {
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  &::placeholder {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
    color: var(--dark-grey-30);
  }
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  -webkit-apperance: 0;
  -moz-apperance: 0;
}

.backgroud {
  overflow: hidden;
  display: block;
  width: 100%;
  // background: #111 url("../img/bg_graphic_login.png") no-repeat
  //   center center;
  background-size: 100%;
  min-height: 100vh;
}

.animationBack {
  // background-position-y: -20vh;

  // animation-duration: 0.5s;
  // animation-name: backgroundUp;
  // opacity: 1;
}

@keyframes backgroundUp {
  from {
    background-position-y: 0px;
    opacity: 0;
  }

  to {
    background-position-y: -20vh;
    opacity: 1;
  }
}

.animationContainer {
  animation-duration: 1.5s;
  animation-name: up;
  display: block;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes up {
  from {
    transform: translate(-50%, -30%);
    opacity: 0;
    display: none;
  }

  to {
    transform: translate(-50%, -50%);
    opacity: 1;
    display: block;
  }
}

.recatangleTextBtn.no {
  opacity: 0.2;
  &:hover {
    background-color: var(--dark-main-blue-50);
    box-shadow: none;
  }
  &:active {
    color: var(--white);
    background-color: var(--dark-main-blue-50);
  }
}

.dimmed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 80;
  overflow: hidden;
  &.none {
    display: none;
  }
}

.hidden {
  width: calc(100vw - 10px);
  height: calc(100vh - 10px);
  overflow: hidden;
  margin-right: 10px;
  background-color: var(--black-dark-mode);
}

.redPoint {
  position: relative;
  &:after {
    position: absolute;
    right: -4px;
    top: -2px;
    z-index: 4;
    display: block;
    content: "";
    width: 8px;
    height: 8px;
    background-color: var(--dark-sub-coral);
    border-radius: 50%;
  }
}

.no_data {
  display: inline-block;
  width: 30%;
  height: 100%;
  min-height: 20px;
  background: var(--dark-grey-10) !important;
  border-radius: 4px;
}

.no_data2 {
  background: var(--dark-grey-10) !important;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0) !important;
}

.card {
  position: relative;
  top: 0;
  transition: top 0.2s;
  & > div {
    cursor: pointer;
  }
  &:hover {
    top: -4px;
  }
}

.gradient {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 103%;
  height: 103%;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.6),
    var(--dark-opacity-30)
  );
}

body,
.scroll {
  scrollbar-width: thin;
  scrollbar-color: transparent;

  /* Webkit Scrollbar */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Mozilla Scrollbar */
  &::-moz-scrollbar {
    width: 10px;
  }

  /* Thumb Styles */
  &::-webkit-scrollbar-thumb,
  &::-moz-scrollbar-thumb {
    background-color: var(--dark-grey-40);
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  /* Track Styles */
  &::-webkit-scrollbar-track,
  &::-moz-scrollbar-track {
    background-color: var(--black);
    border-radius: 0;
    /* Uncomment if needed */
    /* box-shadow: inset 0px 0px 5px var(--black); */
  }

  /* Button Styles */
  &::-webkit-scrollbar-button:single-button {
    display: block;
    background-size: 10px;
    background-repeat: no-repeat;
  }

  /* Up Button */
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: 12px;
    width: 16px;
    background-position: center 4px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(99, 99, 99)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  /* Down Button */
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 12px;
    width: 16px;
    background-position: center 2px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(99, 99, 99)'><polygon points='0,0 100,0 50,50'/></svg>");
  }
}

/* Specific Scrollbar Styles */
.whiteBackScroll {
  &::-webkit-scrollbar-track {
    background-color: var(--white);
  }

  &::-webkit-scrollbar-thumb,
  &::-moz-scrollbar-thumb {
    opacity: 0.2;
  }
}

.scrollNone {
  scrollbar-width: thin;
  scrollbar-color: transparent;
  &::-webkit-scrollbar {
    width: 10px;
    opacity: 0;
  }
  /* Up */
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    opacity: 0;
    height: 12px;
    width: 16px;
    background-position: center 4px;
    background: rgba(0, 0, 0, 0);
  }
  /* Down */
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    opacity: 0;
    height: 12px;
    width: 16px;
    background-position: center 4px;
    background: rgba(0, 0, 0, 0);
  }
}

.hover {
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
  }
}

.backgroundLogo {
  // background: rgba(0, 0, 0, 0) url("../img/img_bg_symbol_graphic.png") no-repeat;
  background-size: 90% auto;
  background-attachment: fixed;
  background-position: center;
  .main {
    background-color: rgba(0, 0, 0, 0);
  }
}

.info-section {
  max-width: 1920px;
  margin: 0 auto;
  .mainHeader {
    // max-width:100%;
  }
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-container {
  margin: 0 75px;
}

.admin-main {
  min-width: 965px;
  max-width: 1133px;
  padding-bottom: 200px;
  padding-top: 144px;
  margin: 0 auto;
}

//운동 목적 3개까지 보여지게
.exKind {
  span {
    display: none;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      display: block;
    }
  }
}

//로딩
.loading {
  width: 24px;
  height: 24px;
  transform: rotate(0deg);
  animation: loading 1.5s linear 0s infinite normal;
  img {
    width: 100%;
    height: 100%;
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.kiosk {
  max-width: 1920px;
  margin: 0 auto;
}

.Mui-selected {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  opacity: 1 !important;
  border: none;
  color: #fff !important;
}

.MuiTabs-scrollButtons {
  width: 112px !important;
  &:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
  }
  &:last-child {
    border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
  }
  svg {
    width: 60px;
    height: 60px;
    fill: var(--white);
  }
}

.infinite-scroll-component {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
  row-gap: 64px;
}

.MuiTabs-scrollableX {
  min-width: calc(100vw - 311px);
}

.Mui-disabled {
  opacity: 0.1 !important;
}

.timeanimation {
  animation: time 1s infinite;
  margin: 0 4px;
}

@keyframes time {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

//반응형=================================================================
@media only screen and (min-width: 1279px) and (max-width: 1523px) {
  .MuiTabs-scrollableX {
    min-width: calc(100vw - 234px);
  }
}

@media only screen and (min-width: 1524px) {
  .MuiTabs-scrollableX {
    min-width: calc(1524px - 224px);
  }
}

//세로
@media only screen and (max-height: 800px) {
  // .backgroud {
  //   // background-size: 100%;
  //   // background-position-y: 80px;
  //   // padding-bottom: 56px;
  // }

  // .animationBack {
  //   background-position-y: 0px;
  // }

  // @keyframes backgroundUp {
  //   from {
  //     background-position-y: 0px;
  //     opacity: 0;
  //   }

  //   to {
  //     background-position-y: 0px;
  //     opacity: 1;
  //   }
  // }

  // .animationContainer {
  //   transform: translate(-50%, 0);
  //   top: 80px !important;
  // }

  // @keyframes up {
  //   from {
  //     transform: translate(-50%, 100px);
  //   }

  //   to {
  //     transform: translate(-50%, 0);
  //   }
  // }
}

//가로
@media only screen and (min-width: $pc1920) {
  // .useinfo-container {
  //   // width: 1687px;
  //   margin: 0 auto;
  // }
}

@media only screen and (min-width: $pc1440) {
  // .sideNav{
  //   width:calc(240px + 0.5vw);
  // }

  .main {
    flex: 1;
  }
}

@media only screen and (max-width: 1439px) {
  // .useinfo-container {
  //   width: 1224px;
  //   margin: 0 auto;
  // }

  .main {
    flex: 1;
  }
}

@media only screen and (max-width: $pc1280) {
  .backgroud {
    // background-size: auto 100%;
  }
  .main-section {
    min-width: 1280px;
  }
}

@media only screen and (max-width: $tablet1279) {
  .main {
    padding-left: 0;
  }
  .main-section {
    min-width: 100%;
  }
  .useinfo-container {
    padding: 0 32px;
  }
}

@media only screen and (max-width: $mobile960) {
  .main-section {
    min-width: 960px;
  }
}

@media only screen and (max-width: 670px) {
  .backgroud {
    background-image: url("../img/bg_graphic_login_m.png");
    background-size: 100%;
    background-position-y: 80px;
    padding-bottom: 56px;
  }

  // .animationBack {
  //   background-position-y: 0px;
  // }

  @keyframes backgroundUp {
    from {
      background-position-y: 0px;
      opacity: 0;
    }

    to {
      background-position-y: 0px;
      opacity: 1;
    }
  }

  .animationContainer {
    transform: translate(0, 0);
    top: 0;
    left: 0;
  }

  @keyframes up {
    from {
      transform: translate(0, 100px);
      opacity: 0;
      display: none;
    }

    to {
      transform: translate(0, 0);
      opacity: 1;
      display: block;
    }
  }
}

//다크 모드
// @media (prefers-color-scheme: dark) {
//   .App {
//     background-color:$light-black-mode;
//   }
// }
