
.rectangleSelect {
  width: 100%;
  height: 100%;
  position: relative;
  .inputArea {
    height: 100%;
    min-height:50px;
    padding: 0 16px;
    border-radius: 4px;
    border: solid 1px rgba(255,255,255,0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    input {
      outline: none;
      width: 85%;
      color: var(--dark-grey-80);
      cursor: pointer;
      height:100%;
      display:block;
    }
    i {
      display: flex;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
    }
    &::placeholder {
      font-family: "Noto Sans KR", sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: -0.3px;
      color: var(--dark-grey-30);
    }
    &.err {
      border-color: red;
    }
  }
  .menus {
    width: calc(100% + 2px)  !important;
    overflow: hidden;
    background-color: var( --black);
    border-radius: 0 0 4px 4px;
    // padding-bottom: 8px;
    position: absolute;
    top: 51px;
    left: 0;
    z-index: 3;
    color:var(--dark-grey-80);
    .menuArea {
      overflow-y: auto;
      max-height: 300px;
      border: 1px solid rgba(255,255,255,0.3);
      border-top: none;
      border-radius: 0 0 4px 4px;
      .menu {
        border-top: 1px solid rgba(255,255,255,0.3);
        line-height: 40px;
        margin: 0 8px;
        padding: 4px 0;
        text-indent: 8px;
        cursor: pointer;
        position: relative;
        z-index: 2;
        p {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        svg {
          margin-right: 8px;
        }
        &:hover {
          p {
            background-color: var(--dark-main-blue-10);
          }
        }
      }
    }
  }
  .error {
    color: var(--dark-sub-coral);
    display: flex;
    align-items: center;
    margin-top: 4px;
    svg {
      margin-right: 4px;
    }
  }
}


.closeArea {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: 90vw;
  height: 90vh;
  background-color: rgba(238, 224, 224, 0);
}

.rectangleSelect.open {
  .inputArea {
    background-color: var( --black);
    border-radius: 4px 4px 0 0;
  }
}
