.produceTable {
  min-width: 928px;
  max-width: 1133px;
  .produceTbody {
    min-height: 200px;
    max-height: 610px;
  }
  .produceList {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    border: 1px solid var(--black-dark-mode);
    border-bottom: 1px solid var(--dark-opacity-20);
    border-left-color: rgba(0, 0, 0, 0);
    border-right-color: rgba(0, 0, 0, 0);
    transition: all 0.5s;
    gap: 24px;
    &:first-child {
      border-top: 1px solid var(--dark-opacity-20);
    }
    &:hover {
      border: solid 1px var(--dark-main-blue-50);
    }
  }
  .produceListImg {
    width: 152px;
    min-width: 152px;
    height: 96px;
    background-color: var(--dark-grey-10);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .title {
    flex: 1;
    min-width: 215px;
    max-width: 400px;
    color: var(--dark-grey-80);
    display: flex;
    white-space: pre-line;
    word-break: break-all;
    flex-direction: column;
    gap: 8px;
  }
  .date,
  .time {
    width: 120px;
    min-width: 120px;
    text-align: center;
    color: var(--dark-grey-80);
    min-width: 120px;
  }
  .line {
    display: inline-block;
    width: 1px;
    height: 18px;
    background-color: var(--dark-opacity-20);
    margin: 0 4px;
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    width: 189px;
    min-width: 189px;
    .submit {
      background: var(--dark-sub-picton-blue);
      color: var(--Black);
    }
  }

  .loading {
    p {
      height: 96px;
    }
    .title {
      span {
        width: 160px;
        height: 16px;
      }
    }
    .date,
    .time {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        width: 92px;
        min-width: 92px;
        height: 16px;
      }
    }
  }
  .listTop {
    display: flex;
    gap: 24px;
    margin-bottom: 16px;
    padding: 0 16px;
    height: 20px;
    .produceListImg {
      height: 20px;
      background-color: rgba(0, 0, 0, 0);
    }
    .th {
      color: var(--dark-grey-60);
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.ja {
  .homeStudio .topBtns .leftBtns .produceBtn {
    width: 137px;
  }
  .homeStudio .topBtns .leftBtns .uploadeBtn {
    width: 200px;
  }
  .homeStudio .topBtns .addNewBtn {
    width: 200px;
  }
  .produceTable .btns {
    width: 235px;
  }
}

.en {
  .homeStudio .topBtns .leftBtns .produceBtn {
    width: 172px;
  }
  .homeStudio .topBtns .leftBtns .uploadeBtn {
    width: 141px;
  }
  .homeStudio .topBtns .addNewBtn {
    width: 227px;
  }
  .produceTable .date,
  .produceTable .time,
  .produceTable .listTop .th {
    min-width: 173px;
    width: 173px;
  }
}

.ch {
  .homeStudio .topBtns .leftBtns .produceBtn {
    width: 121px;
  }
}
