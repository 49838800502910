.alertModifyModal{
    position:fixed;
    top:24px;
    left:50%;
    opacity: 1;
    transform: translateX(-50%);
    display:inline-block;
    width: 654px;
    border-radius: 8px;
    border: solid 2px var(--dark-opacity-80);
    background-color: var( --black);
    padding:30px 40px;
    text-align: center;
    animation-duration: 0.4s;
    animation-name: open;
    z-index: 100;
    .title, .text{
        color:var( --white);
        text-align: center;
        margin-bottom:16px;
        white-space: pre-line;
    }
    .text{
        width:360px;
        margin:0 auto;
        line-height: 1.29;
        white-space: pre-line;
        word-break: keep-all;
    }
    .btns{
        display: flex;
        justify-content: center;
        gap:8px;
        margin-top:16px;
        .btn1, .btn2{
            width:74px;
        }
    }
    .blue{
        font-family: "Noto Sans KR", sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: var(--dark-main-blue-50);
    }

    .modifyArea{
        width:360px;
        margin:16px auto 0;
    }
    .modifyTop{
        display:flex;
        align-items: center;
        justify-content: space-between;
        .modifyTitle, .textCount{
            color: var(--dark-grey-60);
        }
    }
    .modifyInput{
        border-radius: 4px;
        border: solid 1px var(--dark-opacity-80);
        background-color: var(--dark-opacity-10);
        height: 34px;
        margin-top:8px;
        input{
            display:block;
            color: var(--white);
            width:calc(100% - 24px);
            margin:8px auto;
        }
    }
    
}

.alertModifyModal.off{
    animation-duration: 0.4s;
    animation-name: off;
    display:none;
}

@keyframes open {
    from {
        top : -700px;
        opacity: 0;
    }
  
    to {
        top:24px;
        opacity: 1;
    }
}
  

@keyframes off {
    from {
        display:block;
        opacity: 1;
    }
  
    to {
        display:none;
        opacity: 0;
    }
}
  