.exerciseDay {
  border: solid 2px var(--dark-opacity-10);
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: var(--dark-opacity-10);
  .topNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: var(--dark-opacity-10);
    height: 66px;
    .topNavCheck {
      display: flex;
      margin-left: 24px;
      align-items: center;
      .no_data {
        width: 100px;
      }
      .title {
        color: var(--dark-grey-80);
      }
      .checkIcon {
        width: 24px;
        height: 24px;
        margin-left: 8px;
        svg {
          width: 100%;
          height: 100%;
          fill: var(--dark-main-blue-50);
        }
      }
      .checkNum {
        margin-left: 8px;
        color: var(--dark-grey-40);
      }
    }
    .dayNav {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-right: 24px;
      .dayBtnBox {
        // width:38px;
        height: 38px;
        // float:right;
        // margin-left:12px;
        // &.open{
        //     width:168px;
        // }
        .no_data {
          width: 38px;
          height: 38px;
          border-radius: 50%;
        }
      }
    }
  }
  .exerKindArea {
    // background-color: var(--dark-opacity-10);
    padding: 0 24px;
    visibility: hidden;
    height: 0;
    opacity: 0;
    transition: all 0.5s;
    .exerKindSet {
      padding-bottom: 8px;
      display: none;
      &.lineNone .exerciseKindBorder {
        border-bottom: none;
      }
    }
    .exerGroupSet {
      display: none;
      .exerciseKindBorder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        border-bottom: 1px solid var(--dark-opacity-20);
        padding-bottom: 8px;
      }
      .chevron {
        width: 20px;
        height: 20px;
        margin-right: 16px;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
          fill: var(--dark-sub-lavender-blue);
        }
      }
      .exerciseKindLeft {
        display: flex;
        align-items: center;
        .kindImg {
          width: 130px;
          height: 98px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 24px;
          border-radius: 8px;
          border: 1px solid var(--dark-grey-10, #272727);
          overflow: hidden;
          img {
            height: 100%;
            object-fit: cover;
          }
          svg {
            width: 100%;
            height: 100%;
          }
        }
        .kindName {
          color: var(--dark-grey-80);
        }
      }
    }
  }
  &.open {
    border-color: var(--dark-opacity-80);
    .exerKindArea {
      visibility: visible;
      height: auto;
      opacity: 1;
      padding-bottom: 16px;
      .exerKindSet {
        display: block;
      }
    }
    .exerGroupSet {
      display: block;
    }
  }
}

.exerciseDay .exerKindArea .exerKindSet.noline {
  border-bottom: none !important;
}
