.rectangleTextBtn {
  width: 100%;
  padding: 0 24px;
  border-radius: 4px;
  -moz-border-radius: 4px !important;
  -khtml-border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  background-color: var(--dark-main-blue-50);
  cursor: pointer;
  color: var(--white);
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 52px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  &:hover {
    background-color: var(--dark-main-blue-50);
    box-shadow: 0 4px 8px 0 var(--dark-opacity-60);
  }
  &:focus {
    box-shadow: 0px 0px 2px 4px #3e5f8f;
  }
  &:active {
    color: var(--white);
    background-color: var(--dark-main-blue-30);
  }
  &:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
  }
}

.rectangleTextBtn.no {
  cursor: default;
  opacity: 0.4;
  &:hover {
    background-color: var(--dark-main-blue-50);
    box-shadow: none;
  }
  &:active {
    color: var(--white);
    background-color: var(--dark-main-blue-50);
  }
}

.rectangleTextBtn.normal {
  line-height: 34px !important;
  font-size: 14px;
  &:hover {
    box-shadow: none;
  }
}

.rectangleTextBtn.line {
  background-color: rgba(0, 0, 0, 0);
  border: solid 1px var(--dark-grey-30);
  padding: 0 24px;
  line-height: 50px !important;
  color: var(--dark-grey-60);
  &:hover {
    box-shadow: none;
    opacity: 0.5;
  }
  &.no {
    border: solid 1px var(--dark-grey-50);
    &:hover {
      background-color: rgba(0, 0, 0, 0);
      border: solid 1px var(--dark-grey-50);
      padding: 0 24px;
      line-height: 50px !important;
      color: var(--dark-grey-60);
      opacity: 0.2;
    }
  }
}

.rectangleTextBtn.normal.line {
  line-height: 32px;
}

.rectangleTextBtn.gray {
  color: rgba(255, 255, 255, 0.7);
  background-color: var(--dark-opacity-20);
  &:hover {
    color: var(--dark-grey-60);
    background-color: var(--dark-opacity-50);
    box-shadow: none;
  }
}

.rectangleTextBtn.gray2 {
  color: var(--white);
  background-color: var(--dark-opacity-20);
  &:hover {
    color: var(--white);
    background-color: var(--dark-opacity-60);
    box-shadow: none;
  }
}

.rectangleTextBtn.gray3 {
  color: rgba(0, 0, 0, 0.7);
  background-color: var(--light-opacity-20);
  &:hover {
    opacity: 0.7;
  }
}

.rectangleTextBtn.black {
  color: var(--white);
  background-color: var(--black);
  &:hover {
    color: var(--white);
    background-color: var(--black);
  }
}
