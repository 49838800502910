.exerciseInfo {
  .title {
    width: 100%;
    span {
      display: inline-block;
      color: var(--white);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 26px;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      max-width: 100%;
    }
  }
  .info {
    margin: 3px 0 8px;
    height: 18px;
    display: flex;
    align-items: center;
    span {
      color: var(--white);
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    }
    .line {
      width: 1px;
      height: 12px;
      background-color: var(--dark-opacity-80);
      margin: 0 8px;
      display: inline-block;
    }
  }
  &.noData {
    .info {
      margin-top: 3px;
    }
  }
}
