.h1 {
  font-family: "GmarketSansBold";
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
}
.h2 {
  font-family: "GmarketSansBold";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
}

.d1 {
  font-family: "GmarketSansMedium";
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
}

.d2 {
  font-family: "GmarketSansMedium";
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
}

.d3 {
  font-family: "GmarketSansMedium";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
}
.d4 {
  font-family: "GmarketSansMedium";
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
}
.gmarketd1 {
  font-family: "GmarketSansMedium";
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
}

.d-menu {
  font-family: "Fredoka One", cursive;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
}
.fredokaOned1 {
  font-family: "Fredoka One", cursive;
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
}
.fredokaOneH1 {
  font-family: "Fredoka One", cursive;
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
}

.d1_bold {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 44px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: -0.3px;
}

.d1_medium {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 44px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: -0.3px;
  }
  .d1_regular {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 44px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: -0.3px;
  }
  .d1_light {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 44px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: -0.3px;
  }
  .h1_bold {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
  }
  .h1_medium {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
  }
  .h1_regular {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
  }
  .h1_light {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
  }
  .h2_bold {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
  }
  .h2_medium {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
  }
  .h2_regular {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
  }
  .h2_light {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
  }
  .h3_blod {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
  }
  .h3_medium {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
  }
  .h3_Regular {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
  }
  .h3_light {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
  }
  .h4_bold{
    font-family: "Noto Sans KR", sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: -0.3px;
  }
  .h4_medium {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: -0.3px;
  }
  .h4_regular {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: -0.3px;
  }
  .h4_light {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: -0.3px;
  }
  .body_medium {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
  }
  .body_regular {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
  }
  .body_light {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
  }
  .caption1_medium {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.3px;
  }
  .caption1_regular {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.3px;
  }
  .caption1_light {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.3px;
  }
  .caption2_medium {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.3px;
  }
  .caption2_regular {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.3px;
  }
  .caption2_light {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.3px;
  }
  .caption3_bold {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.3px;
  }
  .caption3_medium {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.3px;
  }
  .caption3_regular {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.3px;
  }
  .caption3_light {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.3px;
  }

  // global

  
  