.dayBtn{
  width: 38px;
  height: 38px;
  border-radius: 19px;
  text-align: center;
  line-height:42px;
  background-color: var(--black);
  color: var(--dark-grey-40);
  font-family: "GmarketSansMedium";
  letter-spacing: -1px;
  cursor: pointer;
  // border: solid 1px var(--black);
  transition: all 0.5s;
  b{
    margin-right:2px;
  }
  i {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: 1px;
    svg {
      width: 100%;
      height: 100%;
      fill: var(--white);
    }
  }
  &.on {
    // border-color: var(--dark-main-blue-50);
    color: var(--dark-main-blue-50);
  }
  &.open {
    min-width: 131px;
    width: auto;
    padding: 0 12px 0 14px;
    border-radius: 19px;
    border-color: var(--dark-main-blue-50);
    background-color: var(--dark-main-blue-50);
    flex: 1;
    color: var(--white);
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400 !important;
    gap: 4px;
    font-size: 16px;
    font-weight: normal;
    display:flex;
    align-items: center;
    justify-content: center;
    .text {
      font-family: "GmarketSansMedium";
      letter-spacing: -1px;
      font-size: 18px;
      margin-top:4px;
    }
    i{
      display:flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.today {
    width: auto;
    padding: 0 12px 0 12px;
    border-color: var(--dark-main-blue-50);
    gap: 4px;
    font-size: 16px;
    font-weight: normal;
    font-family: "Noto Sans KR", sans-serif;
    display:flex;
    align-items: center;
    justify-content: center;
    color: var(--dark-main-blue-50);
    .text {
      font-family: "GmarketSansMedium";
      letter-spacing: -1px;
      font-size: 18px;
      margin-top:4px;
      color: var(--dark-main-blue-50);
    }
  }
}
