
.noData{
    .img{
        width:48px;
        height:48px;
        margin:0 auto 18px;
        svg{
            width:100%;
            height:100%;
            fill:var(--dark-grey-30);
        }
    }
    .text{
        color:var(--dark-grey-30);
        text-align: center;
    }
}