.exerciseDay {
  .exerciseKind {
    display: flex;
    align-items: center;
    .chevron{
      width:20px;
      height:20px;
      margin-right:16px;
      svg{
        width:100%;
        height:100%;
        fill: var(--dark-opacity-10);
      }
      &.group{
        margin-right:12px;
        svg{
          fill : var(--dark-sub-lavender-blue);
        }
      }
    }
    .exerciseKindBorder{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex:1;
      border-bottom: 1px solid var(--dark-opacity-20);
      padding-bottom:8px;
    }
    .exerciseKindLeft {
      display: flex;
      align-items: center;
      .kindImg {
        width: 130px;
        height: 98px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 24px;
        border-radius: 8px;
        border: 1px solid var(--dark-grey-10, #272727);
        overflow: hidden;
        img{
          height: 100%;
          object-fit: cover;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .kindName {
        color: var(--dark-grey-80);
      }
    }
  }

  .exerGroupSet {
    height:auto;
    .exerGoupTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .exerGoupTopTitle {
        color: var(--dark-grey-50);
      }
      .exerciseKindInputArea{
        margin-right:48px;
      }
    }
    .groupSet{
      padding-bottom:8px;
      margin-left:94px;
    }
  }

  .exerciseKindRight {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  
  .exerciseKindInputArea {
    display: flex;
    align-items: center;
    .caption1_regular {
      color: var(--dark-grey-80);
      margin-right: 8px;
    }
    .countValue {
      width: 48px;
      height: 28px;
      margin: 0 0 0 8px;
      border-radius: 4px;
      background-color: var(--dark-opacity-10);
      text-align: center;
      line-height: 28px;
      color: var(--white);
    }
    &.no p{
      visibility: hidden;
    }
  }
  .questionIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
      fill: var(--dark-sub-lavender-blue);
    }
  }
}
