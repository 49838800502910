.emphasis-box {
  width: 100%;
  margin-top: 32px;
  border-radius: 8px;
  background-color: var(--dark-opacity-10);
  display: flex;
  flex-direction: column;
  gap: 16px;
  user-select: none;
  .pharsetitle {
    padding-left: 24px;
    padding-top: 24px;
    text-align: left;
    color: var(--dark-main-blue-50);
  }
  .pharsecontent {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-left: 10px;
    .pharse {
      display: flex;
      flex-direction: row;
      gap: 6px;
      color: var(--dark-grey-60);
      align-items: center;
      .whitedot {
        display: inline-block;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background-color: var(--dark-grey-60);
      }
    }
  }
}

.table--graph {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom:80px;
  .graph-title {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: space-between;
    user-select: none;
    .title {
      width: 140px;
      height: 18px;
      text-align: center;
      color: var(--dark-grey-60);
    }
    .amount {
      width: 145px;
    }
  }
  .graph-content {
    display: flex;
    flex-direction: column;
    border-top: solid 1px var(--white);
    border-bottom: solid 1px var(--white);
    min-height: 68px;
    .content-box {
      height: 68px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 24px;
      align-items: center;
      border-bottom: solid 1px var(--dark-opacity-60);
      .data {
        width: 140px;
        text-align: center;
        color: var(--white);
      }
      .amount {
        width: 145px;
      }
    }
  }
  .graph-content .content-box:last-child {
    border-bottom: none;
  }
  .loadingContainer{
    height:588px;
  }
}

.table--tagline {
  height: 26px;
  margin: 64px 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .tag {
    text-align: left;
    color: var(--white);
    user-select: none;
  }
  .date {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    text-align: right;
    color: var(--white);
    cursor: pointer;
  }
  .tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    max-width: 100px;
    span {
      color: white;
    }
    .chevron {
      fill: white !important;
    }

  }
}

.contentbox {
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  border-bottom: solid 1px var(--dark-opacity-60);
  .data {
    width: 140px;
    text-align: center;
    color: var(--white);
  }
  .amount {
    width: 145px;
  }
}

.inquiry--tablebox {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  margin-top:64px;
  .tabletitle {
    height: 18px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    text-align: center;
    color: var(--dark-grey-60);
    .title {
      width: 140px;
    }
    .content {
      width: 100%;
    }
    .payamount {
      width: 145px;
      min-width: 145px;
    }
  }
  .tablecontent {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    border-top: solid 1px var(--white);
    border-bottom: solid 1px var(--white);
  }
  .tablecontent .row:last-child {
    border: none;
  }
  .cscenter--btn{
    margin-top:80px;
    transition: all 0.2s;
    &:hover{
      opacity: 0.7;
    }
  }
}

.inquirytitle {
  height: 33px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  text-align: center;
  color: var(--dark-grey-60);
  border-bottom: solid 1px var(--white);
  user-select: none;
  padding:0 24px;
  .no,
  .title{
    width: 140px;
  }
  .sort{
    min-width:100px;
  }
  .date {
    margin-left: auto;
    width: 140px;
    min-width:140px;
  }
  .title {
    width: 100%;
  }
  .state {
    width: 100px;
    min-width:100px;
    text-align: center;
  }
}

.inquiry__table {
  cursor: pointer;
  margin-bottom:80px;
  .table .state {
    border: none;
  }
  .table :last-child {
    border-bottom: solid 1px var(--white);
  }
}

.row {
  height: 68px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  text-align: center;
  border-bottom: solid 1px var(--dark-opacity-60);
  .column {
    width: 140px;
    color: var(--white);
  }
  .title {
    width: 100%;
  }
  .amount {
    min-width: 145px;
    margin-left: auto;
  }
}

.inquirylist {
  height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  border-bottom: solid 1px var(--dark-opacity-60);
  padding:0 24px;
  span {
    user-select: none;
  }
  .no{
    text-align: center;
    color: var(--white);
    width: 140px;
    
  }
  .sort{
    min-width:100px;
    color: var(--white);
    text-align: center;
  }
  .date {
    margin-left: auto;
    width: 140px;
    min-width:140px;
    color: var(--white);
    text-align: center;
  }
  .title {
    text-align: left;
    color: var(--white);
    width: 100%;
    &:hover{
      text-decoration: underline;
    }
  }
  .state {
    width: 100px;
    min-width:100px;
    color: var(--white);
    text-align: center;
  }
}

.credit--no-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 200px auto;
  align-items: center;
  user-select: none;
  .no-pay {
    color: var(--white);
  }
}
