
.exerciseType{
    display:flex;
    .typeIcon{
        width:30px;
        height:30px;
        border-radius: 50%;
        overflow: hidden;
        margin-left:-4px;
        display:flex;
        align-items: center;
        justify-content: center;
        span{
            display:flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            overflow: hidden;
            width:28px;
            height:28px;
            background-color: var(--black);
        }
        &.toolIcon{
            background-color: rgba(52, 197, 255, 0.5);
            svg{
                width:20px;
                height:20px;
                fill : var(--dark-sub-picton-blue);
            }
        }
        &.bodyIcon{
            background-color: rgba(255, 167, 35, 0.5);
            svg{
                width:28px;
                height:28px;
                // fill : var(--dark-sub-orange);
            }
            img{
                width:100%;
            }
        }
        &.numIcon{
            background-color: var(--dark-grey-20);
            color: var(--dark-grey-80);
            span{
                color: var(--dark-grey-80) !important;
            }
        }
    }
}