.attachmentfile {
  display: flex;
  flex-direction: row;
  align-items: center;
  .removeicon {
    position: absolute;
    margin: -32px;
    cursor: pointer;
  }
  .label {
    width: 498px;
    height: 50px;
    margin: 0 16px 0 0;
    border-radius: 4px;
    border: solid 1px var(--dark-opacity-70);
    background-color: var(--black-2);
    cursor: pointer;
    color: var(--dark-grey-50);
    font-size: 13px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: var(--dark-opacity-10);
    }
  }
  .file-selected {
    color: var(--white);
  }
  .register {
    display: none;
  }
  .uploadicon {
    cursor: pointer;
  }
}

.inquiry--term {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 18px;
  text-align: left;
  color: var(--dark-grey-60);
  margin-top:24px;
  .no {
    margin-left: 4px;
  }
  .termcontent {
    user-select: none;
    color:rgba(255,255,255, 0.7)
  }
}

.read-inquiry {
  width: 100%;
  display: flex;
  flex-direction: column;
  // padding: 24px 0px;
  // margin: 24px 0px;
  border-bottom: 1px solid var(--dark-grey-50);
  .backbtn{
    margin-top:64px;
    margin-bottom:40px;
  }
  .title, .nickname{
    color: var(--white);
    margin-bottom:16px;
  }
  .customer-input {
    width: 100%;
    color: var(--white);
    pre{
      padding: 24px 0px;
      overflow: auto;
      white-space: pre-wrap; 
    }
  }
  .customer-files {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin:24px 0;
    p {
      color: var(--dark-grey-60);
      padding-right: 10px;
    }
    .file, .downloadImage {
      width: 48px;
      height: 48px;
      border-radius: 4px;
      background-color: var(--dark-grey-20);
      margin-left: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      svg {
        height: 60%;
        width: 60%;
        opacity: 0.6;
        fill : var(--white);
      }
      img{
        width:100%;
        height:100%;
        object-fit: cover;
      }
    }
  }
}

.inquiry-response__answer {
  h2 {
    color: var(--white);
    margin:32px 0 24px;
  }
  pre{
    color: var(--white);
  }
}

.answerArea{
  padding:16px;
  border-radius: 4px;
  border: solid 1px var(--dark-opacity-70);
  margin-bottom:40px;
  min-height:200px;
  p{
    color: var(--white);
  }
  pre{
    overflow: auto;
    white-space: pre-wrap;
  }
}

.inquiry-response__null {
  display: flex;
  flex-direction: column;
  margin: 80px auto 0;
  .icon {
    text-align: center;
    margin-bottom: 32px;
  }
  .waiting {
  text-align: center;
    color: var(--white);
  }
}

.inquirytag {
  // FAQ, 결제 내역, Credit 내역 헤드와 달리 1:1 문의 header는 다름..
  text-align: left;
  color: var(--white);
  margin: 32px 0 24px;
}


.backbtn {
  width: 108px;
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: solid 1px var(--white);
  text-align: left;
  color: var(--white);
  cursor: pointer;
  .title {
    color: var(--white);
  }
  svg{
    width: 20px;
    height: 20px;
    fill: var(--white);
  }
}
