@import "assets/scss/size.scss";

.grandientTitle {
  font-family: "GmarketSansMedium";
  background-image: linear-gradient(to right, #55b9f4 28%, #2e5bd2 70%);
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  display: inline-block;
  width: 100%;
  word-break: keep-all;
}

@media only screen and (max-width: 670px) {
  .grandientTitle {
    text-align: left;
    background-image: linear-gradient(to right, #55b9f4, #2e5bd2 30%);
    font-size: 44px;
    letter-spacing: -0.5px;
    line-height: 50px;
  }
}

@media only screen and (max-width: $mobile468) {
  .grandientTitle {
    font-size: 40px;
    letter-spacing: -1px;
    line-height: 44px;
  }
}
