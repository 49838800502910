.squareCardContainer {
  min-width: 197px;
  display: block;
  .squareCard {
    width: 100%;
    min-width: 100%;
    position: relative;
    .card1 {
      .programImgArea {
        width: 100%;
        padding-bottom: 63%;
        position: relative;
        overflow: hidden;
        border-radius: 4px;
        .programImg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: var(--dark-grey-10);
          }
        }
        .profileImg {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid var(--white);
          overflow: hidden;
          position: absolute;
          bottom: 12px;
          left: 12px;
          background-color: var(--white);
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      .squareTextBox {
        // padding: 12px 0 8px;
      }
      .exerciseTypeRectangle {
        margin: 16px 0;
      }
    }
  }
  .exerciseInfo .title span,
  .card1 .programImgArea .programImg img,
  .exercisePointText p,
  .exercisePointText p span {
    transition: all 0.8s;
  }
  &:hover {
    .card1 .programImgArea .programImg img {
      transform: scale(1.2);
    }
    .exerciseInfo .title span {
      border-bottom: 1px solid var(--white);
    }
    .exercisePointText p,
    .exercisePointText p span {
      color: var(--white);
    }
  }

  .trainingType {
    display: flex;
    align-items: center;
    gap:4px;
    padding:10px 0 6px;
    span.no_data {
      min-height: 10px;
      width: 10%;
      padding: 0;
    }
  }
}
