
.pageNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 8px;
  .pageBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    span {
      width: 38px;
      height: 38px;
      flex-grow: 0;
      border: solid 1px var(--dark-grey-20);
      text-align: center;
      line-height: 40px;
      display: block;
      cursor: pointer;
      color: var(--dark-grey-50);
      display: flex;
      justify-content: center;
      align-items: center;
      &.on {
        color: var(--dark-main-blue-50);
        border-color: var(--dark-main-blue-50);
      }
    }
  }
  .navBtn {
    width: 38px;
    height: 38px;
    border: solid 1px var(--dark-grey-20);
    text-align: center;
    line-height: 38px;
    color: var(--dark-grey-50);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--dark-grey-50);
    }
  }
  // .prev2,
  // .prev {
  //   svg {
  //     transform: scale(0.5);
  //   }
  // }
  .noEvent{
    border: solid 1px var(--dark-grey-20);
    svg{
      fill : var(--dark-grey-20);
    }
  }
}


//페이지 네비게이션
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 8px;
  li {
    width: 38px;
    height: 38px;
    border: solid 1px var(--dark-grey-20);
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    a{
      color: var(--dark-grey-50);
    }
    svg {
      width:16px;
      height:14px;
      fill: var(--dark-grey-50);
    }
    &.active {
      border-color: var(--dark-main-blue-50);
      a{
        color: var(--dark-main-blue-50);
      }
      svg {
        fill: var(--dark-grey-20);
      }
    }
    &.disabled{
      svg{
        fill : var(--dark-grey-20);
      }
    }
  }
}