.analysisBtn {
  padding: 12px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  background: var(--dark-grey-10);
  margin-bottom: 24px;
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
  }
  span {
    color: var(--dark-grey-80);
  }
  &:hover {
    background: var(
      --gradient_key_1,
      linear-gradient(94deg, #007ff4 0%, #6f00c6 100%)
    );
  }
}
