.alertModal {
  position: fixed;
  top: 24px;
  left: 50%;
  opacity: 1;
  transform: translateX(-50%);
  display: inline-block;
  width: 636px;
  border-radius: 8px;
  border: solid 2px var(--dark-opacity-80);
  background-color: var(--black);
  padding: 30px 40px;
  text-align: center;
  animation-duration: 0.4s;
  animation-name: open;
  z-index: 100;
  .title {
    color: var(--dark-main-blue-50);
  }
  .title,
  .text {
    text-align: center;
    margin-bottom: 16px;
    white-space: pre-line;
  }
  .text {
    line-height: 1.29;
    white-space: pre-line;
    color: var(--white);
  }
  .btn {
    display: flex;
    justify-content: center;
    width: 74px;
    margin: 0 auto;
  }
  &.err .text span {
    color: var(--dark-sub-coral);
  }
  &.success .text span {
    color: var(--dark-sub-picton-blue);
  }
}

.alertModal.off {
  animation-duration: 0.4s;
  animation-name: off;
  display: none;
}

.marginTop {
  margin-top: 4px;
  display: block;
}

@keyframes open {
  from {
    top: -700px;
    opacity: 0;
  }

  to {
    top: 24px;
    opacity: 1;
  }
}

@keyframes off {
  from {
    display: block;
    opacity: 1;
  }

  to {
    display: none;
    opacity: 0;
  }
}

.ja {
  .alertModal .btns .btn1,
  .alertModal .btns .btn2 {
    width: 124px;
  }
}

.ch {
  .alertModal .btns .btn1,
  .alertModal .btns .btn2 {
    width: 77px;
  }
}
