.exerciseTypeRectangle{
    display:flex;
    .typeIcon{
        // width:30px;
        height:30px;
        border-radius: 4px;
        overflow: hidden;
        display:flex;
        align-items: center;
        justify-content: center;
        border: solid 1px var(--black);
        margin-right:4px;
        span{
            display:flex;
            align-items: center;
            justify-content: center;
            border-radius:4px;
            overflow: hidden;
            width:30px;
            height:30px;
            background-color: var(--black);
        }
        &.toolIcon{
            border-color: var(--dark-sub-picton-blue);
            svg{
                width:24px;
                height:24px;
                fill : var(--dark-sub-picton-blue);
            }
        }
        &.bodyIcon{
            border-color: var(--dark-sub-orange);
            svg{
                width:28px;
                height:28px;
                // fill : var(--dark-sub-orange);
            }
            img{
                width:100%;
            }
        }
        &.numIcon{
            border:none;
            width:32px;
            height:32px;
            span{
                width:100%;
                height:100%;
                background-color: var(--dark-opacity-20);
                color: var(--dark-grey-80) !important;
                display:block;
                text-align: center;
                line-height:32px;
            }
        }
        .tagText{
            border-color: var(--dark-sub-picton-blue);
            color: var(--dark-sub-picton-blue);
            width:100%;
            padding:0 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            line-height:32px;
        }
    }
}