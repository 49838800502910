.exerciseDetail {
  position: relative;
  padding-bottom: 200px;
  background-color: #0c0c0c;
  width: 100%;
  .blurArea {
    position: absolute;
    width: 100%;
    height: 960px;
    overflow: hidden;
  }
  .blurImg {
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
    width: 100%;
    height: 960px;
    filter: blur(24px);
    -webkit-filter: blur(24px);
    opacity: 0.2;
    img {
      width: 100%;
    }
  }
  .blur {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-repeat: no-repeat;
    width: 100%;
    height: 960px;
    background-image: linear-gradient(
      to bottom,
      rgba(12, 12, 12, 0) 0%,
      #0c0c0c 100%
    );
  }
  .exerciseDetailContainer {
    margin: 0 75px;
  }
  .exerciseDetailBox {
    position: relative;
    max-width: 1675px;
    padding-top: 89px;
    width: 965px;
    margin: 0 auto;
    .symbol {
      position: absolute;
      top: 94px;
      right: 0;
    }
    .infoArea {
      margin: 40px 0;
      display: flex;
      .img {
        width: 440px;
        height: 280px;
        min-width: 440px;
        border-radius: 4px;
        margin-right: 32px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          background-color: var(--dark-grey-10);
        }
      }
      .exerciseInfoArea {
        border-top: 1px solid var(--dark-opacity-60);
        border-bottom: 1px solid var(--dark-opacity-60);
        padding: 24px;
        width: 493px;
        overflow-y: auto;
        height: 280px;
        display: flex;
        flex-direction: column;
        .exerciseToolBodyList {
          clear: both;
          flex: 1;
          &.body {
            margin-top: 16px;
          }
        }
        .icon {
          display: inline-block;
          padding: 6px 8px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          border: 1px solid var(--primary-blue, #34c5ff);
          color: var(--primary-blue, #34c5ff);
          margin-right: 8px;
          margin-bottom: 8px;
        }
        .title {
          color: var(--white);
          margin-bottom: 12px;
        }
      }
      .exerciseDescBox {
        display: block;
        &::before {
          display: block;
          content: "";
          clear: both;
        }
        .tooltip {
          float: left;
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
        &.tool .typeTooltip .tooltipBox .typeIcon {
          border-color: var(--dark-sub-picton-blue);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          svg {
            fill: var(--dark-sub-picton-blue);
          }
        }
        &.body .typeTooltip .tooltipBox .typeIcon {
          border-color: var(--dark-sub-orange);
        }
        .tooltip {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          margin-bottom: 8px;
        }
      }
    }
    .programTitle {
      color: var(--white);
      word-break: break-all;
    }
    .programInfo {
      color: var(--white);
      margin: 24px 0;
      opacity: 0.7;
      line-height: 1.25;
    }
    .exercisePointText {
      p {
        font-size: 14px;
        color: var(--white);
        span {
          font-size: 14px;
          color: var(--light-sub-picton-blue);
          font-weight: bold;
          margin-top: -1px;
        }
      }
    }
    .pointArea {
      display: flex;
      justify-content: space-between;
      .pointRight {
        display: flex;
        justify-content: end;
        gap: 8px;
      }
    }

    .profileStart {
      margin: 40px 0 80px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .profileInfo {
        display: flex;
        gap: 16px;
        align-items: center;
        .circleProfileIcon {
          width: 56px;
          height: 56px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .maker {
          color: var(--white);
          span {
            color: var(--white);
            display: block;
          }
        }
      }
    }
  }

  .waringArea {
    color: var(--dark-grey-80);
    display: flex;
    margin-bottom: 24px;
    .wraringIcon {
      color: var(--dark-sub-coral);
      margin-right: 16px;
      display: flex;
      min-width: 76px;
      svg {
        width: 18px;
        height: 18px;
        fill: var(--dark-sub-coral);
        margin-right: 4px;
      }
    }
    pre {
      color: var(--dark-grey-80);
      word-break: break-all;
    }
  }
  .nodataTooltipBox {
    width: 88px;
    height: 88px;
    border-radius: 4px;
  }
}

@media only screen and (max-width: 1343px) {
  .exerciseDetail {
    .blurArea,
    .blurImg {
      max-width: 100%;
      width: 100%;
    }
    .exerciseDetailBox {
      width: 100%;
      min-width: 810px;
      .infoArea {
        .exerciseInfoArea {
          width: 40%;
          flex: 1;
        }
      }
    }
  }
}
