.symbol {
  display: flex;
  align-items: center;
  gap: 4px;
}

.videoSymbol {
  color: var(--dark-sub-orange, #ffa723);
  svg {
    fill: var(--dark-sub-orange, #ffa723);
  }
}

.kemaSymbol {
  color: var(--dark-sub-coral);
  svg {
    fill: var(--dark-sub-coral);
  }
}

.schoolSymbol {
  color: var(--dark-sub-green);
  svg {
    fill: var(--dark-sub-green);
  }
}
