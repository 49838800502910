.alertModal2 {
  position: fixed;
  top: 24px;
  left: 50%;
  opacity: 1;
  transform: translateX(-50%);
  display: inline-block;
  width: 654px;
  border-radius: 8px;
  border: solid 2px var(--dark-opacity-80);
  background-color: var(--black);
  padding: 30px 40px;
  text-align: center;
  animation-duration: 0.4s;
  animation-name: open;
  z-index: 100;
  .title,
  .text {
    color: var(--white);
    text-align: center;
    margin-bottom: 16px;
    white-space: pre-line;
  }
  .text {
    line-height: 1.29;
    white-space: pre-line;
  }
  .btns {
    display: flex;
    justify-content: center;
    gap: 8px;
    .btn1,
    .btn2 {
      // width:74px;
    }
  }
  .blue {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: var(--dark-main-blue-50);
  }
}

.red {
  color: #ff0000;
}

.alertModal2.off {
  animation-duration: 0.4s;
  animation-name: off;
  display: none;
}

@keyframes open {
  from {
    top: -700px;
    opacity: 0;
  }

  to {
    top: 24px;
    opacity: 1;
  }
}

@keyframes off {
  from {
    display: block;
    opacity: 1;
  }

  to {
    display: none;
    opacity: 0;
  }
}

// .ja{
//     .alertModal2 .btns .btn1, .alertModal2 .btns .btn2 {
//         width: 124px;
//     }
// }

// .ch{
//     .alertModal2 .btns .btn1, .alertModal2 .btns .btn2 {
//         width: 77px;
//     }
// }
