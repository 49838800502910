
.squareTextBox{
    display:inline-block;
    padding: 2px 4px;
    color: var(--light-grey-10);
    border-radius: 4px;
    background-color:var(--light-opacity-80);
    max-height:24px;
}

.squareTextBoxNoTitle{
    margin-top:4px;
}