@import "assets/scss/size.scss";

.homeStudio {
  padding: 144px 75px 0px;
  min-width: 965px;
  max-width: 1133px;
  margin: 0 auto;
  .studioTitle {
    color: var(--white);
    margin-bottom: 24px;
    font-weight: 700;
  }
  .topBtns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftBtns {
      display: flex;
      gap: 8px;
      align-items: center;
      .produceBtn {
        min-width: 130px;
        height: 42px;
      }
      .uploadeBtn {
        width: 136px;
        height: 42px;
      }
    }
    button {
      font-size: 16px;
      display: flex;
      flex-direction: row-reverse;
      padding: 0 16px !important;
      height: 100%;
      font-weight: 500;
      border-radius: 8px;
      svg {
        width: 20px;
        height: 20px;
        fill: var(--dark-grey-40);
        margin-right: 4px;
      }
    }

    .addNewBtn {
      min-width: 183px;
      height: 42px;
      button {
        border-color: var(--dark-grey-80);
        color: var(--dark-grey-80);
        svg {
          fill: var(--dark-grey-80);
        }
      }
    }
    .on {
      button {
        color: var(--dark-main-blue-50);
        border-color: var(--dark-main-blue-50);
        svg {
          fill: var(--dark-main-blue-50);
        }
      }
    }
  }
  .homeStudioContainer {
    margin-top: 32px;
  }
  .pageArea {
    margin-top: 80px;
  }
  .noDataArea {
    margin: 240px 0;
    .text {
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: $tablet1279) {
  .homeStudio {
    padding: 144px 32px 200px;
    min-width: 896px;
  }
}
