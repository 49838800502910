@import "assets/scss/size.scss";

.login {
  position: relative;
  min-height: 100vh !important;
  height: 680px;
  .appJoin {
    position: fixed;
    top: 56px;
    right: 64px;
    z-index: 100;
    .appJoinTitle {
      color: var(--White, #fff);
      text-align: right;
      font-size: 20px;
    }
    .qrs {
      margin-top: 40px;
      display: flex;
      gap: 40px;
      justify-content: flex-end;
      img {
        width: 164px;
        height: 298px;
      }
    }
  }
  .form {
    width: 400px;
    .title {
      margin-bottom: 40px;
    }
    .loginBtn {
      margin: 16px 0 32px;
      cursor: pointer;
      button {
        width: 100%;
      }
    }
    .checkInputArea {
      width: 140px;
    }
    .loginInputArea {
      display: flex;
      margin-bottom: 16px;
      .label {
        color: var(--dark-grey-60);
        line-height: 52px;
        width: 87px;
        margin-left: -87px;
      }
      .passLookBtn {
        margin-top: 5px;
        cursor: pointer;
      }
      .delectBtn {
        margin-top: 5px;
        cursor: pointer;
      }
    }
    .linkArea {
      display: flex;
      justify-content: center;
      margin: 28px 0 44px;
      height: 28px;
      .idPass {
        width: 127px;
        cursor: pointer;
      }
      .joinBtn {
        width: 90px;
        cursor: pointer;
        margin-left: 24px;
      }
      a {
        display: flex;
        align-items: center;
        color: var(--dark-grey-60);
      }
      svg {
        margin-right: 2px;
      }
    }
  }

  .endBtn {
    color: var(--white);
    display: flex;
    align-items: center;
    gap: 8px;
    position: fixed;
    bottom: 56px;
    right: 56px;
  }
}

//다국어
.en {
  .login .form .linkArea {
    .idPass {
      width: 128px;
    }
    .joinBtn {
      width: 177px;
    }
  }
}
.ja {
  .login .form {
    .idPass {
      width: 169px;
    }
    .checkInputArea {
      width: 170px;
    }
  }
}

//반응형
@media only screen and (max-width: 670px) {
  .login {
    height: 788px;
    .form {
      width: 100%;
      .fromConainer {
        width: 400px;
        margin: 80px auto 0;
      }
      .loginInputArea {
        display: block;
        .label {
          display: block;
          margin-left: 0;
          line-height: 1.29;
          margin-bottom: 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile480) {
  .login {
    .form {
      top: 80px;
      width: 100%;
      .fromConainer {
        width: calc(100% - 32px);
        padding: 0 16px;
        margin-top: 0;
      }
      .title {
        font-size: 40px;
        margin-bottom: 16px;
      }
      .loginBtn {
        button {
          font-size: 16px;
        }
      }

      .loginInputArea {
        display: block;
        .label {
          display: block;
          margin-left: 0;
          line-height: 1.29;
          margin-bottom: 8px;
        }
        div {
          width: auto !important;
        }
        p {
          width: 287px !important;
        }
      }
    }

    .snsLogin {
      ul {
        grid-template-columns: repeat(1, 1fr);
        gap: 8px;
        li {
          span {
            text-indent: -44px;
          }
        }
      }
    }
  }
}

//prefers-color-scheme
// @media (prefers-color-scheme: dark) {
//   .login {
//     .form {
//       .loginInputArea {
//         .label {
//           color: var(--light-grey-60);
//         }
//       }
//       .linkArea {
//         a {
//           color: var(--light-grey-60);
//         }
//       }
//     }

//     .snsLogin {
//       ul {
//         li {
//           span {
//             border-left: 1px solid var(--dark-opacity-30);
//           }
//         }
//       }
//     }
//   }
// }
