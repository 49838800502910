.sortBtn{
    color: var(--dark-main-blue-50);
    display: flex;
    align-items: center;
    background-color:rgba(0,0,0,0);
    border-radius: 4px;
    transition: all 0.3s;
    padding:2px 4px;
    text-transform: capitalize;
    &.sort0{
      color: var(--dark-grey-60);
    }
    svg{
      width:16px;
      height:16px;   
      fill:var(--dark-main-blue-50);
      margin-left:4px;
      min-width:16px;
      min-height:16px;
    }
    &:hover{
      background-color: var(--dark-opacity-30);
    }
    &:focus{
      background-color: var(--dark-main-blue-20);
      color: var(--dark-grey-60);
      svg{   
        fill:var(--dark-grey-60);
      }
    }
    &:focus:not(:focus-visible) {
      outline: 0;
      box-shadow: none;
      background-color: rgba(0,0,0,0);
      color: var(--dark-main-blue-50);
      svg{
        fill: var(--dark-main-blue-50);
      }
    }
}
