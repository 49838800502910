.VideoEXEnd {
  width: 100vw;
  height: 100vh;
  background: url("../../../assets/img/video_ex_result.png") center no-repeat;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: 0.6s;
  animation-name: open;
  .VideoEXEndContainer {
    .VideoEXEndTitle {
      color: var(--white);
      text-align: center;
    }
    .VideoEXResultArea {
      margin: 64px 0 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;
      .VideoEXResult {
        width: 320px;
        .result {
          width: 320px;
          height: 320px;
          background: url("../../../assets/img/video_result_time.png") center
            no-repeat;
          background-size: 100% auto;
          border-radius: 50%;
          .value {
            padding: 120px 0 24px;
            color: var(--dark-sub-picton-blue, #34c5ff);
            text-align: center;
            font-family: "GmarketSansBold";
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
          }
          .cate {
            color: var(--dark-sub-picton-blue, #34c5ff);
            text-align: center;
          }
        }
        .cateTitle {
          color: var(--dark-sub-picton-blue, #34c5ff);
          text-align: center;
          margin-top:24px;
        }
        &.kcal{
            .result {
                background-image: url("../../../assets/img/video_result_kcal.png");
              }
              .value, .cate, .cateTitle{
                color: var(--dark-sub-orange, #FFA723);
              }
        }
      }
    }
  }
  .btn{
    width: 240px;
    margin:96px auto 0;
    button{
        background-color: var(--dark-sub-picton-blue, #34c5ff);
        font-size: 16px;


    }
  }
}

.VideoEXStart{
    width: 100%;
    height: 100vh;
    display:flex;
    align-items: center;
    justify-content: center;
    video{
      width:100%;
      height:100%;
      object-fit:contain;
    }
    .Xicon{
        width: 48px;
        height: 48px;
        position:fixed;
        top:24px;
        right:24px;
        z-index: 100;
    }

}

.VideoEXStart_1{
    video{
      position:absolute;
      top:0;
      left:0;
      width: 100vw;
      height: 100vh;
      opacity: 0;
      // transition: all 0.3s;
    }
}

@keyframes open {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  