
.circleProfileIcon{
    width:34px;
    height:34px;
    background-color:var( --white);
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    border:1px solid var( --white);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    img{
        width:100%;
        height: 100%;
        object-fit: cover;
    }
}
