.studioProduce {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  background: url("../../assets/img/studio_produce_img.png") center no-repeat;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .produceContainer {
    .title {
      color: var(--white);
      text-align: center;
      margin-bottom: 48px;
    }
  }

  .produceContainer {
    margin-top: 122px;
  }

  .btn_container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    overflow: auto;
  }

  @media (max-width: 1599px) {
    .btns {
      justify-content: center !important;
      max-width: 100%;
      justify-content: center;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 1600px;
    gap: 32px;
    cursor: pointer;

    .btn {
      padding: 0 24px;
      box-sizing: border-box;
      border-radius: 24px;
      background: var(--white);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      width: 500px;
      height: 360px;
      .btnTitle {
        margin: 116px 0 32px;
        text-align: center;
      }
      .btnDesc {
        text-align: center;
      }
      .btnDesc1 {
        color: var(--dark-sub-picton-blue);
      }
      .btnDesc5 {
        color: var(--dark-sub-orange);
      }
      .btnDesc6 {
        color: var(--dark-sub-coral);
      }
      .btnDesc9 {
        color: var(--dark-sub-green);
      }
    }
  }
}
