@import "assets/scss/size.scss";

.viewAll {
  padding-top: 96px;
  padding-bottom: 106px;
  min-height: 100%;
  max-width: 1524px;
  margin: 0 auto;
  .title {
    color: var(--white);
  }
  .infinite-scroll-component {
    margin: 32px 0 80px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 24px;
    row-gap: 64px;
  }
  .page {
    // margin-left:-296px;
  }

  button.MuiButtonBase-root.MuiTab-root {
    padding: 12px 24px;
  }

  .nodata {
    padding-top: 300px;
  }
}

@media only screen and (max-width: 1918px) {
  .viewAll {
    .infinite-scroll-component {
      grid-template-columns: repeat(4, calc(25% - 20px));
    }
  }
}

@media only screen and (max-width: 1598px) {
  .viewAll {
    .infinite-scroll-component {
      grid-template-columns: repeat(3, calc(33% - 8px));
      gap: 15px;
      row-gap: 64px;
    }
  }
}

@media only screen and (max-width: $tablet1279) {
  .viewAll {
    margin: 0 32px;
    min-width: 856px;
    .infinite-scroll-component {
      grid-template-columns: repeat(4, calc(25% - 20px));
      gap: 25px;
      row-gap: 64px;
    }
  }
}

@media only screen and (max-width: $tablet1199) {
  .viewAll {
    .infinite-scroll-component {
      grid-template-columns: repeat(3, calc(33% - 8px));
      gap: 15px;
      row-gap: 64px;
    }
  }
}
