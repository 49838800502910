.inputModal {
  width: 720px;
  height: 244px;
  position: fixed;
  top: 24px;
  left: 50%;
  opacity: 1;
  transform: translateX(-50%);
  display: inline-block;
  border-radius: 8px;
  border: solid 2px var(--dark-opacity-80);
  background-color: var(--black);
  text-align: center;
  animation-duration: 0.4s;
  animation-name: open;
  z-index: 100;
  p {
    text-align: center;
    color: var(--white);
  }
  .inputModalTitle {
    margin-top: 24px;
  }
  .inputModalDesc {
    margin: 8px 0 16px;
    line-height:36px;
  }
  .changeArea {
    width: 240px;
    height: 34px;
    border-radius: 4px;
    border: solid 1px var(--dark-opacity-80);
    background-color: var(--dark-opacity-10);
    margin:0 auto 4px;
    display:flex;
    align-items: center;
    justify-content: center;
    input{
        background-color:rgba(0,0,0,0);
        color: var(--white);
        outline: none;
        border:none;
    }
    &.error{
      border: solid 1px var(--dark-sub-coral);
    }
  }
  .errormsg{
    color: var(--dark-sub-coral);
    display:flex;
    align-items: center;
    width: 240px;
    margin:0 auto 12px;
    height:16px;
    gap:4px;
    svg{
      width:14px;
      height:14px;
      fill: var(--dark-sub-coral);
    }
  }
  .inputModalBtns{
      display:flex;
      gap:8px;
      justify-content: center;
      button{
        width: 74px;
        height: 34px;
        line-height: 34px !important;
        padding:0;
      }
  }
}

.inputModal.off{
    animation-duration: 0.4s;
    animation-name: off;
    display:none;
}

@keyframes open {
  from {
    top: -700px;
    opacity: 0;
  }

  to {
    top: 24px;
    opacity: 1;
  }
}

@keyframes off {
  from {
    display: block;
    opacity: 1;
  }

  to {
    display: none;
    opacity: 0;
  }
}
