@import "assets/scss/size.scss";

.exerciseDescModal {
  position: fixed;
  top: 100vh;
  left: 50%;
  opacity: 1;
  transform: translate(-50%, -50%);
  width: 540px;
  min-height: 594px;
  max-height: 90vh;
  box-shadow: 0 4px 10px 0 var(--dark-opacity-30);
  background-color: var(--white);
  visibility: hidden;
  transition: top 0.7s;
  opacity: 0;
  z-index: 100;
  padding-bottom: 24px;

  &.off {
    visibility: visible;
    top: 50%;
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  .box {
    position: relative;
    width: 476px;
    margin: 32px auto 24px;
    min-height: 486px;
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 1px solid var(--dark-grey-80);
    .exerciseProfile {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .circleProfileIcon {
        width: 40px;
        height: 40px;
        border: none;
        box-shadow: none;
        background-color: var(--dark-sub-lavender-blue);
      }
      .title {
        line-height: 40px;
        margin-left: 16px;
      }
    }
    .exerciseImg {
      width: 100%;
      height: 317px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
      // background:url("../../../assets/img/video_back_img.png") no-repeat 100% center;
      position: relative;
      video,
      canvas,
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      canvas {
        object-fit: cover;
      }
      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .exerciseDescArea {
      max-height: 170px;
      padding-bottom: 16px;
    }
    .exerciseDesc {
      color: var(--light-grey-60);
      margin-bottom: 16px;
      .red {
        color: var(--light-sub-coral);
      }
      .subTitle {
        color: var(--light-grey-40);
        margin-bottom: 4px;
      }
      p {
        white-space: pre-line;
      }
    }
  }

  .btn {
    width: 180px;
    margin: 0 auto;
    button {
      width: 180px;
    }
  }
}

@media only screen and (max-width: 670px) {
  .exerciseDescModal {
    width: 100%;
    transform: translate(0, 0);
    top: auto;
    bottom: 0;
    left: 0;
    border-radius: 24px 24px 0 0;
    .dragBar {
      width: 80px;
      height: 4px;
      flex-grow: 0;
      margin: 24px auto 0;
      border-radius: 2px;
      background-color: var(--light-grey-30);
      display: block;
    }
    .title {
      text-align: center;
    }
    .btns {
      display: grid;
      grid-template-columns: 1.5fr 3fr;
      button {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $mobile468) {
  .exerciseDescModal {
    border-radius: 16px 16px 0 0;
    .dragBar {
      margin-top: 16px;
    }
    .box {
      padding: 24px 16px 32px;
    }
  }
}
