.imgLink{
    width: 152px;
    min-width: 152px;
    height: 96px;
    background-color: var(--dark-grey-10);
    a{
        width:100%;
        height:100%;
        display:block;
        img{
            width:100%;
            height:100%;
            object-fit: cover;
        }
    }
}